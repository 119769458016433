.instruction {
    margin-top: 100px;
    &__container {
        max-width: 820px;
        margin: auto;
        padding: 0 5%;
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
    }
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: var(--theme-main-ticket-button-text);
        margin: 0;
        margin-bottom: 30px;
    }
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &__count {
            min-width: 65px;
            min-height: 65px;
            border-radius: 25px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            font-size: 30px;
            line-height: 35px;
            text-align: center;
            color: #D73A2B;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        }
        &__info {
            display: flex;
            flex-direction: column;
            margin-left: 25px;
            &__title {
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                color: var(--theme-main-ticket-button-text);
                margin: 0;
                margin-bottom: 5px;
            }
            &__text {
                font-weight: 400;
                font-size: 20px;
                line-height: 23px;
                color: var(--theme-main-ticket-button-text);
            }
        }
    }
}

@media (max-width: 768px) {
    .instruction {
        margin-top: 70px;
        &__item {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 35px;
            &__info {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
}