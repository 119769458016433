.footer-alfabank {
    margin-top: 70px;
    &__container {
        max-width: 1150px;
        margin: auto;
        padding: 0 5%;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between; 
        padding: 32px 48px;
        background: #ffffffb3;
        backdrop-filter: blur(7.5px);
        border-radius: 24px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }
    &__text {
        font-weight: 500;
        font-size: 20px;
        color: var(--theme-main-ticket-button-text);
        max-width: 730px;
    }
    &__button {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #D73A2B;
        padding: 16px 40px;
        background: #FFFFFF;
        border-radius: 30px;
        text-decoration: none;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }
}

@media (max-width: 768px) {
    .footer-alfabank {
        &__wrapper {
            flex-direction: column;
            padding: 20px;
        }
        &__text {
            font-size: 18px;
            text-align: center;
        }
        &__button {
            margin-top: 30px;
        }
    }
}