.returns {
    &_container {
        max-width: 872px;
        margin: auto;
        padding: 0 10px;
    }
    &_modal {
        padding: 20px 24px !important;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &_title {
        font-weight: 600;
        font-size: calc(16px + 8 * (100vw / 1280));
        text-align: center;
        color: var(--theme-main-ticket-button-text);
        margin: 0;
        margin-bottom: 15px;
        width: 100%;
    }
    &_title::after {
        display: block;
        content: '';
        width: 95%;
        margin: 0 auto;
        height: 2.5px;
        margin-top: 10px;
        background: var(--theme-headerline);
        border-radius: 10px;
    }
    &_info {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--theme-main-ticket-button-text);
        margin-bottom: 50px;
        text-align: justify;
    }
    &_form {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 450px;
        width: 100%;
        // background-color: var(--theme-background);
        border-radius: 16px;
        padding: 0px 0 30px;
        .react-tel-input {
            .form-control {
                border-radius: 10px !important;
            }
        }
        &_final {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            max-width: 450px;
            opacity: 0;
            transition: opacity .4s ease;
            &_text {
                margin-top: 30px;
                font-weight: 500;
                display: flex;
                color: #fff !important;
                text-align: center;
                
            }
        }

        &_text {
            display: block;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            text-align: center;
            color: #FFFFFF;
            margin: 20px 0 10px 0;
            &_link {
                color: #fff !important; 
            }
        }

        &_send {
            cursor: pointer;
            width: 110px;
            height: 41px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #F3F3F7;
            border-radius: 10px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            text-align: center;
            color: #000000;
            margin-top: 15px;
            transition: all .2s ease;
            border: none;
            span {
                cursor: pointer;
                color: #000;
            }
        }
        &_send:hover {
            background: #d5d5d9;
        }
        &_send:active {
            background: #b7b7bb;
        }
    }
    &_list {
        margin: 0 auto 50px 0;
        padding: 0;
        padding-left: 20px;
        margin-top: -20px;
        li {
            font-size: 16px;
            color: var(--theme-main-ticket-button-text);
            
            margin-bottom: 3px;
            font-weight: 600;
            a {
                color: var(--theme-main-ticket-button-text);;
                text-decoration: underline;
            }
        }
    }
}

.loader {
    border: 2.5px solid transparent;
    border-top: 2.5px solid #000;
    border-left: 2.5px solid #000;
    border-right: 2.5px solid #000;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin .8s linear infinite;
    margin-right: 10px;
  }
      
  @keyframes spin {
    0%  {
      transform: rotate(0deg);
    }
         
    100%  {
      transform: rotate(360deg);
    }      
  }

@media (max-width: 470px) {
    .returns {
        &_form {
            &_inputs {
                width: 100%;
            }
            &_input {
                width: 100%;
                input {
                    width: 100% !important;
                }
            }
            
        }
    }
}