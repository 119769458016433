.alfabank-top-section {
    padding-top: 100px;
    overflow: hidden;
    &__container {
        max-width: 1380px;
        margin: auto;
    }
    &__main {
        display: flex;
        justify-content: space-between;
        height: calc(100vh - 130px);
        background: rgba(255, 255, 255, 0.478);
        backdrop-filter: blur(7.5px);
        border-radius: 20px;
        // overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        
    }
    &__content, &__image {
        // width: 50%;
    }
    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FFFFFF;
        border-radius: 0px;     
        width: 400px;
        height: 70px;    
        padding-top: 5px;
        margin-top: 10px;
        &_img {
            display: block;
            height: 60px;
        }
    }
    &__content {
        width: 50%;
        // background: #7534ED;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding: 0 0 60px 5%;
    }
    &__image {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        img {
            width: 100%;
        }
    }
    &__title {
    
        font-weight: 600;
        font-size: 56px;
        line-height: 64px;
        color: var(--theme-main-ticket-button-text);
        margin: 0;
        margin-bottom: 55px;
        max-width: 610px;

    }
    &__button {
        padding: 16px 40px 19px;
        background-color: #FFFFFF;
        border-radius: 30px;
        margin: 0 auto 0 0;
        cursor: pointer;
        transition: background-color .2s linear, color .2s linear, box-shadow .2s linear;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        color: #EA2119;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }
    &__button:hover {
        background-color: hsla(0,0%, 100%, .8);
    }
}

@media (max-width: 768px) {
    .alfabank-top-section {
        padding-top: 80px;
        &__container {
            padding: 0 10px;
        }
        &__main {
            flex-direction: column;
            border-radius: 20px;
            overflow: hidden;
            max-height: 500px;
        }
        &__content {
            width: 100%;
            padding: 32px 24px;
            align-items: center;
        }
        &__image {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__title {
            font-size: 24px;
            line-height: 32px;
            margin: 12px 0 20px 0;
            text-align: center;
        }
        &__button {
            margin: 0 auto;
        }
    }
}