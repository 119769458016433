.btns {
    margin-top: auto;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    width: 230px;
    justify-content: space-between;
    margin: 0 auto;
    z-index: 10;
}
.btn-prev, .btn-next {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    width: 108px;
    height: 34px;
    border-radius: 10px;
    cursor: pointer;
    transition: .2s ease;
    -webkit-appearance: none;
}
.btn-prev {
    background: #FFF;
    color: #637882;
    border: 1px solid #637882;
}
.btn-next {
    background: var(--theme-main-application-button);
    color: #FFF;
    border: 1px solid transparent;
}
.btn-prev:hover {
    background: rgb(231, 231, 231);
}
.btn-prev:active {
    background: rgb(207, 207, 207);
}
.btn-next:hover {
    background: var(--theme-main-application-button-hover);
}
.btn-next:active {
    background: var(--theme-main-application-button-active);
}
.btn-prev:disabled, .btn-next:disabled {
    cursor: not-allowed;
    opacity: .7;
}
.btn-prev:disabled:hover {
    background: #FFF;
}
.btn-prev:disabled {
    background: #FFF;
}