body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
        "Segoe UI Symbol" !important;

    --theme-background: #3dbbac;
    --theme-header: #3dbbac; 
    --theme-headerline: #e5ff49;

    --theme-main-ticket-button: #e5ff49;
    --theme-main-ticket-button-hover: #D6FF48;
    --theme-main-ticket-button-active: #a5c434;

    --theme-main-application-button: #0EB2B7;
    --theme-main-application-button-hover: #24d3d9;
    --theme-main-application-button-active: #09888d;

    --theme-main-ticket-button-text: #000000;
    --theme-main-gray: #0d0d0d;
}

::-webkit-scrollbar {
    width: 0;
}

html.dark body {
   
}

h1,
h2,
h3,
h4,
h5,
h6,
img {
    user-select: none;
    cursor: default;
}

img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

span,
p {
    user-select: none;
}
