.about {
    padding-top: 70px;

    border-radius: 0px 0px 20px 20px;
    overflow: hidden;
    will-change: auto;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    &_container {
        max-width: 1920px;
        margin: auto;
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0 0 20px 20px;
        overflow: hidden;
    }
    &_wrapper {
        height: 500px;
        max-width: 1920px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0px 0px 20px 20px;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        overflow: hidden !important;
    }
    &_video {
        z-index: -1;
        position: relative;
        width: 100%;
        &_block {
            background: rgba(0, 0, 0, 0.57);
            position: absolute;
            max-width: 1920px;
            width: 100%;
            height: 500px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            z-index: 3;
            border-radius: 0px 0px 20px 20px;
            overflow: hidden;
            padding: 50px 0 20px 0;
        }
        &_title {
            color: #FFFFFF;
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: calc(30px + 15 * (100vw / 1280));
            max-width: 660px;
            margin: 0;
        }
        &_short {
            display: flex;
            width: 100%;
            max-width: 1280px;
            justify-content: space-between;
            &_item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: calc(100% / 4);
                &_number {
                    font-weight: 600;
                    font-size: calc(30px + 15 * (100vw / 1280));
                    color: #FFFFFF;
                }
                &_description {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 23px;
                    color: #FFFFFF;
                }
            }
        }
    }
    &.small {
      border-radius: 0px 0px 20px 20px;
      &_container {
          max-width: 1200px;
          height: 300px;
      }
      &_wrapper {
          height: 300px;
      }
      &_video {
          &_block {
              height: 300px;
          }
      }
  }
}

.about-scroll {
  transition: .5s opacity;
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 100px;
  }
  &_text {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: var(--theme-main-ticket-button-text);
    margin-bottom: 25px;
    text-align: center;
  }
  img {
    transform: translateY(-50%);
    animation: t-arrow-bottom 1.7s infinite ease;
  }
}

.about-container {
    max-width: 1350px;
    margin: auto;
    padding: 0 10px;
}

.short-description {
  margin-top: 35px;
    &_text {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 23px;
        color: var(--theme-main-ticket-button-text);
    }
}

.festival-zones {
  margin-top: 80px;
    &_item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 70px;
        &_title {
            font-weight: 700;
            font-size: 40px;
            line-height: 47px;
            color: var(--theme-main-application-button);
            background: #ffffffb3;
            backdrop-filter: blur(7.5px);
            margin: 0;
            margin-bottom: 15px;
        }
        &_description {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: var(--theme-main-ticket-button-text);
            margin-bottom: 15px;
            max-width: 900px;
            a {
              font-weight: 400;
              font-size: 18px;
              line-height: 22px;
              color: var(--theme-main-ticket-button-text);
            }
        }
        &_additional {
            font-weight: 300;
            font-size: 18px;
            line-height: 22px;
            color: var(--theme-main-ticket-button-text);
            margin-bottom: 30px;
            max-width: 945px;
            a {
              font-weight: 300;
              font-size: 18px;
              line-height: 22px;
              color: var(--theme-main-ticket-button-text);
            }
        }
        &_slider {
          display: block;
          max-height: 180px;
          width: 100%;
          height: 100%;
          border-radius: 16px;
          overflow: hidden;
          will-change: all;
          
          &_slide {
            display: block;
            width: 100%;
            height: 180px;
            border-radius: 16px; 
            overflow: hidden;
            background: rgba(130, 130, 130, 0.2);
            background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
            background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
            background-size: 800px 100px;
            animation: wave-lines 2s infinite ease-out;
            &_img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
      }
      
    }
}

.about-history {
  background: var(--theme-main-bg);
  padding: 50px 0;
  margin-top: -30px;
  &_item {
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    position: relative;
    &_line-end {
      display: block;
      width: 100%;
      height: 3px;
      position: absolute;
      top: 13px;
      left: 0;
      background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 10%) 70%);
    }
    &_line {
      height: 1px;
      border-bottom: 3px solid rgb(255, 255, 255);
      width: 100%;
      position: absolute;
      top: 13px;
      left: 0;
    }
    &_circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      position: absolute;
      top: 4px;
      left: 0;
      z-index: 2;
      background: var(--theme-main-ticket-button);
    }
    &_title {
      font-weight: 600;
      font-size: 25px;
      color: var(--theme-main-ticket-button-text);
      margin-bottom: 20px;
    }
    &_subtitle {
      font-weight: 500;
      font-size: 18px;
      color: var(--theme-main-ticket-button-text);
      margin-bottom: 15px;
    }
    &_text {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--theme-main-ticket-button-text);
      padding-right: 15px;
    }
  }
  &_next {
    position: absolute;
    top: 0;
    right: 0;
  }
  &_back {
    position: absolute;
    top: 0;
    left: 0;
  }
  &_next, &_back {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--theme-main-ticket-button);
    z-index: 2;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    opacity: 1;
    transition: all .2s ease-in-out;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
    }
  }
  &_next:hover, &_back:hover {
    background: var(--theme-main-ticket-button-hover);
  }
  &_title {
    margin: 0;
    font-weight: 600;
    font-size: calc(20px + 20 * (100vw / 1280));
    color: var(--theme-main-ticket-button-text);
    margin-bottom: 10px;
  }
  &_description {
    font-style: normal;
    font-weight: 400;
    font-size: calc(16px + 9 * (100vw / 1280));
    color: var(--theme-main-ticket-button-text);
    display: block;
    margin-bottom: 50px;
    max-width: 765px;
  }
}

.festival-video {
  margin-top: 80px;
  &_container {
    max-width: 1120px;
    margin: auto;
    padding: 0 10px;
  }
  &_title {
    font-weight: 600;
    font-size: calc(20px + 20 * (100vw / 1280));
    color: var(--theme-main-ticket-button-text);
    margin: 0;
    text-align: center;
    margin-bottom: 10px;
  }
  &_wrapper {
    display: flex;
    flex-direction: column;
  }
  &_description {
    font-weight: 400;
    font-size: calc(16px + 9 * (100vw / 1280));
    text-align: center;
    color: var(--theme-main-ticket-button-text);
    text-align: center;
    max-width: 765px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  &_wrap {
    height: 250px;
    display: flex;
    justify-content: space-between;
  }
  &_item {
    width: 40%;
    position: relative;
    border: 3px solid var(--theme-main-ticket-button-text);
    border-radius: 16px;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-height: 930px) {
  .about {
    &_container, &_wrapper, &_video_block {
      max-height: 100%;
      height: 70vh !important;
    }
  }
  .about-scroll {
    &_wrapper {
      height: 30vh !important;
    }
  }
}

@media (max-width: 870px) {
    .about_video {
        width: auto;
        margin: auto;
        &_title,
        &_text {
            font-weight: 700;
        }
        &_ticket {
            margin-top: 20px;
            display: block;
        }
    }

    .info {
        &_wrapper {
            flex-direction: column;
        }
        &_item {
            width: 100%;
            margin-bottom: 60px;
        }
    }
}

@media (max-width: 739px) {
  .festival-video {
    margin-top: 50px;
    &_wrap {
      flex-direction: column;
      height: 100%;
    }
    &_item {
      width: 100%;
      height: 250px;
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 500px) {
  .about-history {
    &_item {
      &_circle {
        left: 40px;
      }
      &_title {
        font-size: 25px;
        margin-bottom: 10px;
      }
      &_subtitle {
        font-size: 25px;
      }
    }
  }
}

@media (max-width: 450px) {
    .about {
        &_general {
            &_text {
                font-size: 14px;
                margin-bottom: 7px;
            }
            &_img {
                width: 100px;
            }
        }
    }
}

@keyframes wave-lines {
    0% {
        background-position: 0px 0;
    }
    100% {
        background-position: 1500px 0;
    }
}

@keyframes price {
    0% {
        box-shadow: 0 0 0 0 #de47bc;
    }
    80% {
        box-shadow: 0 0 0 15px transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

@keyframes t-arrow-bottom {
  0%,to {
      transform: translateY(0) translateX(0%)
  }

  50%,55% {
      transform: translateY(-7px) translateX(0%)
  }
}