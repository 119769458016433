.main {
  padding-top: 70px;
  &_container {
      max-width: 1380px;
      padding: 0 10px;
      margin: auto;
      width: 100%;
  }
  &_grid {
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
      margin-top: 50px;
  }
  &_wrapper {
      max-height: 450px;
      height: 100%;
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 16px;
      overflow: hidden;
      grid-column: 1 / 4;
      grid-row: 1;
      position: relative;
  }
  &_video {
      z-index: -1;
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      display: block;
      &_controls {
        
        position: absolute;
        z-index: 4;
        bottom: 10px;
        left: 10px;
        &_button {
          display: block;
          width: 45px;
          height: 45px;
          cursor: pointer;
        }
      }
      &_block {
          background: rgba(0, 0, 0, 0.3);
          position: absolute;
          width: 100%;
          // max-width: 900px;
          height: 450px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          z-index: 3;
          
      }
      &_title {
          font-weight: 600;
          font-size: 35px;
          color: #fff;
          text-align: center;
          margin: 0;
      }
      &_text {
          font-weight: 600;
          font-size: 45px;
          color: #fff;
          text-align: center;
          margin: 0;
      }
      &_ticket {
          display: none;
          padding: 12px 18px;
          margin-bottom: 10px;
          background: var(--theme-main-ticket-button);
          box-shadow: 0px 0px 15px rgba(88, 86, 214, 0.5);
          border-radius: 10px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: var(--theme-main-ticket-button-text);
          animation: button-pulse 2s infinite;
          transition: .2s;
          text-decoration: none;
          margin-top: 10px;
      }
      &_ticket:hover {
          color: #ffffff !important;
          background: var(--theme-main-ticket-button-hover);
      }
      &_ticket:active {
          background: var(--theme-main-ticket-button-active);
      }
      &_btn {
          font-weight: 600;
          margin-top: 20px;
          background: #43DF91;
          border-radius: 10px;
          text-decoration: none;
          padding: 15px 50px;
          cursor: pointer;
          text-transform: uppercase;
          color: #000;
          font-size: 15px;
          transition: .2s ease;
      }
      &_btn:hover {
          background: #43DF91;
          color: #000;
      }
      &_btn:active {
          background: #43DF91;
          color: #000;
      }
  }
  &_general {
      margin-top: 53px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &_text {
          font-weight: 500;
          font-size: calc(14px + 2 * (100vw / 1280));
          text-align: center;
          color: #FFFFFF;
          padding: 2px 10px;
          border-radius: 10px;
          margin-bottom: 15px;
      }
      &_img {
          width: calc(150px + 60 * (100vw / 1280));
      }
  }
  &_box {
    border-radius: 16px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    color: #000;
    text-decoration: none;
    transition: .3s all ease-in-out;
    transform-origin: bottom right;
    z-index: 3;
    &_title {
      font-size:  25px;
      font-weight: 600;
    }
    &_text {
      font-size: 18px;
      font-weight: 600;
    }
    img {
      cursor: pointer;
    }
  }
  &_box:hover {
    transform: translateY(-10px);
  }
  .b {
    display: none;
      grid-column: 3;
      grid-row: 1;
      background: #2C7FCC;
  }
  .c {
      background: #58e4ca;
      grid-column: 1;
      grid-row: 2;
      height: 150px;
      box-shadow:rgba(0, 0, 0, 0.3) 0px 0px 15px;
      img {
        position: absolute;
        width: 250px;
        right: 0px;
        top: -60px;
      }
  }
  .d {
      background: #EDFF77;
      grid-column: 2;
      grid-row: 2;
      height: 150px;
      box-shadow:rgba(0, 0, 0, 0.3) 0px 0px 15px;
      img {
        position: absolute;
        width: 150px;
        right: 0px;
        top: -10px;
      }
  }
  .e {
    background: #A2FFB1;
    grid-column: 3;
    grid-row: 2;
    height: 150px;
    box-shadow:rgba(0, 0, 0, 0.3) 0px 0px 15px;
    img {
      position: absolute;
      width: 150px;
      right: 20px;
      top: -16px;
    }
  }
}

.info {
  padding: 35px 0;
  &_wrapper {
      display: flex;
      justify-content: center;
  }
  &_item {
      width: calc(90% / 3);
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer !important;
      transition: .3s ease-in-out;
      &_head {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          div, span {
              cursor: pointer !important;
          }
          &_icon {
              display: flex;
              align-items: center;
              width: 78px;
              height: 78px;
              border-radius: 50%;
              border: 2px solid #fff;
              background: transparent;
              transition: .3s ease-in-out;
              cursor: pointer;
              svg {
                  margin-left: 10px;
              }
          }
          &_logo {
              width: 80px;
              transition: transform .2s linear;
          }
          &_title {
              color: #fff;
              font-size: 25px;
              font-weight: 600;
              margin-top: 10px;
              transition: .3s ease;
          }
      }
      &_body {
          display: flex;
          align-items: center;
          cursor: pointer;
          &_text {
              font-size: 18px;
              color: #fff;
              font-weight: 300;
              display: flex;
              flex-direction: column;
              flex-wrap: wrap;
              cursor: pointer !important;
              text-align: center;
          }
      }
  }
  &_item:hover .info_item_head_icon {
      transform: translateY(-7px);
  }
  &_item:hover .info_item_head_title {
      color: #D2FF00;
  }
  &_item:hover .info_item_head_icon {
      border: 2px solid #D2FF00;
  }
}

@media (max-width: 870px) {
  .main {
    &_box:hover {
      transform: translateY(0);
    }
    &_grid {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }
    &_wrapper {
      grid-column: 1;
      grid-row: 1;
      max-height: 500px;
      height: 100%;
    }
    &_video {
      overflow: visible;
      &_block {
        height: 500px;
      }
      &_ticket {
        display: block;
      }
    }
    &_box {
      &_title {
        font-size: 25px;
      }
      &_text {
        font-size: 18px;
      }
    }
    
    .b {
        grid-column: 1;
        grid-row: 2;
        height: 150px;
    }
    .c {
        grid-column: 1;
        grid-row: 3;
    }
    .d {
        grid-column: 1;
        grid-row: 4;
    }
    .e {
      grid-column: 1;
      grid-row: 5;
    }
  }
  .info {
    &_wrapper {
      flex-direction: column;
    }
    &_item {
      width: 100%;
      margin-bottom: 60px;
    }
  }
}

@media (max-width: 450px) {
  .main {
      &_general {
          &_text {
              font-size: 14px;
              margin-bottom: 7px;
          }
          &_img {
              width: 165px;
          }
      }
  }
}

@keyframes wave-lines {
  0% {
      background-position: 0px 0;
  }
  100% {
      background-position: 1500px 0;
  }
}

@keyframes price {
  0% {
      box-shadow: 0 0 0 0 #DE47BC;
  }
  80% {
      box-shadow: 0 0 0 15px transparent;
  }
  100% {
      box-shadow: 0 0 0 0 transparent;
  }
}