// .swiper {
//     width: 100%;
//     height: 100%;
//     background: rgba(130, 130, 130, 0.2);
//     background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
//     background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
//     background-size: 800px 100px;
//     animation: wave-lines 2s infinite ease-out;
// }

@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}

.cookie {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    background: rgb(207, 207, 207);
    padding: 12px 18px;
    margin: 10px;
    border-radius: 12px;
    z-index: 999;
    transition: transform 0.45s ease;
    transform: translateY(0%);
    &_text {
        font-weight: 400;
        font-size: calc(12px + 2 * (100vw / 1280));
        margin-right: 10px;
        max-width: 300px;
        a {
            color: #000000;
        }
    }
    &_btn {
        font-weight: 500;
        font-size: calc(12px + 4 * (100vw / 1280));
        padding: 10px 28px;
        border-radius: 10px;
        background: rgb(255, 255, 255);
        transition: .2s ease-in-out;
        cursor: pointer;
    }
    &_btn:hover {
        background: rgb(195, 195, 195);
    }
    &_btn:active {
        background: rgb(165, 165, 165);
    }
}

.social {
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    
    &_container {
        max-width: 1360px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffffba url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%2331DDBFFF' stroke-width='25' stroke-dasharray='50' stroke-dashoffset='40' stroke-linecap='round'/%3e%3c/svg%3e");
        // background: #fff;
        backdrop-filter: blur(7.5px);
        box-shadow: inset 0px 0px 40px rgba(0, 0, 0, 0.05);
        border-radius: 16px;
    }
    &_links {
        display: flex;
        flex-direction: column;
        padding: 70px 0 70px 45px;
        width: 50%;
        padding-right: 20px;
        &_title {
            font-weight: 600;
            font-size: calc(16px + 10 * (100vw / 1280));
            color: var(--theme-main-gray);
            margin: 0;
            margin-bottom: 10px;
        }
        &_text {
            font-weight: 400;
            font-size: calc(12px + 6 * (100vw / 1280));
            color: #000000;
            margin: 0;
        }
        &_items {
            margin-top: 30px;
            display: flex;
            justify-content: space-between;
            max-width: 562px;
            width: 100%;
            flex-wrap: wrap;
        }
        &_item {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            transition: .2s ease;
        }
        &_item:hover {
            background: #dddddd;
        }
    }
    &_photos {
        padding-right: 45px;
        width: 50%;
        padding-left: 20px;
        &_swiper {
            border-radius: 10px;
        }
    }
    &_photo_item {
        width: 160px;
        height: 160px;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        img {
            display: block;
            width: 100%;
            border-radius: 10px;
            overflow: hidden;
        }
    }
}


.footer {
    background: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(7.5px);
    border-top: 3px solid #31DDBF;
    position: relative;
    z-index: 1;
    &_container {
        max-width: 1360px;
        padding: 0 25px;
        margin: auto;
        display: flex;
        flex-direction: column;
    }
    &_wrapper {
        padding: 30px 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 2.5px solid var(--theme-headerline);
    }
    &_partners {
        display: flex;
        flex-direction: column;
        &_general {
            display: flex;
            flex-direction: column;
            &_text {
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                color: var(--theme-main-gray);
                margin-bottom: 7px;
            }
            &_logo {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #FFFFFF;
                border-radius: 5px;     
                width: 400px;
                height: 50px;    
                padding-top: 5px;
                &_img {
                    display: block;
                    height: 33px;
                }
            }
        }
        &_others {
            margin-top: 18px;
            display: flex;
            flex-wrap: wrap;
            max-width: 400px;
            justify-content: space-between;
            &_text {
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                color: var(--theme-main-gray);
                margin-bottom: 7px;
                flex-basis: 100%;
            }
            &_logo {
                width: 195px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #ffffffe7;
                border-radius: 5px;  
                margin-bottom: 10px;
                &_img {
                    &_1 {
                        max-height: 22px;
                    }
                    &_2 {
                        max-height: 25px;
                    }
                    &_3 {
                        max-height: 40px;
                    }
                    &_4 {
                        max-height: 22px;
                    }
                    &_5 {
                        max-height: 55px;
                    }
                    &_6 {
                        max-height: 35px;
                    }
                    &_7 {
                        max-height: 70px;
                    }
                    &_8 {
                        max-height: 50px;
                    }
                }
            }
        }
    }
    &_organizer {
        max-width: 400px;
        &_info {
            display: flex;
            flex-direction: column;
            &_title {
                font-weight: 700;
                font-size: 12px;
                color: var(--theme-main-gray);
                margin-bottom: 12px;
            }
            &_text {
                font-weight: 500;
                font-size: 12px;
                color: var(--theme-main-gray);
                margin-bottom: 10px;
            }
        }
    }
    &_info {
        display: flex;
        flex-direction: column;
        &_title {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: var(--theme-main-gray);
            margin-top: 5px;
            margin-bottom: 12px;
        }
        &_links {
            display: flex;
            flex-direction: column;
            &_item {
                font-weight: 600;
                font-size: 12px;
                line-height: 14px;
                color: var(--theme-main-gray);
                text-decoration: none !important;
                margin-bottom: 7px;
            }
        }
        &_logo {
            width: 300px;
            margin-top: 15px;
            &_img {
                width: 100%;
            }
        }
    }
    &_copyright {
        display: flex;
        flex-direction: column;
        &_text {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            color: var(--theme-main-gray);
            padding: 15px 0;
        }
    }
}

.fade-out-top {
    animation: fade-out-top .3s cubic-bezier(.23, 1, .32, 1) both;
    z-index: 1;
}

.fade-in-bottom {
    animation: fade-in-bottom .6s cubic-bezier(.23, 1, .32, 1) both;
    z-index: 1;
}

@keyframes fade-out-top {
    0% {
        opacity: 1;
        transform:translateX(0)
    }

    to {
        opacity: 0;
        transform:translateX(-50%)
    }
}

@keyframes fade-in-bottom {
    0% {
        opacity: 0;
        transform:translateX(70%)
    }

    to {
        opacity: 1;
        transform:translateX(0)
    }
}

@media (max-width: 400px) {
    .cookie {
        padding: 10px;
        &_text {
            font-size: 11px;
        }
        &_btn {
            font-size: 12px;
        }
    }
}

@media (max-width: 880px) {
    .footer {
        &_wrapper {
            flex-direction: column;
        }
        &_partners {
            margin-bottom: 30px;
        }
    }
    .footer::before, .footer::after {
        display: none;
    }
    .social {
        &_links {
            width: 100%;
            padding: 0;
            order: 2;
            align-items: center;
            &_title {
                text-align: center;
            }
            &_text {
                text-align: center;
            }
            // &_items {
            //     width: 150px;
            // }
            &_item {
                margin-bottom: 20px;
            }
        }
        &_wrapper {
            flex-direction: column;
            padding: 10px;
        }
        &_photos {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
            order: 1;
            margin-bottom: 30px;
            margin-top: 30px;
        }
    }
}

@media (max-width: 525px) {
    .footer {
        &_container {
            padding: 0 10px;
        }
        &_partners {
            &_general {
                &_logo {
                    width: 100%;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                }
            }
            &_others {
                max-width: 100%;
                &_logo {
                    width: 49%;
                    height: 45px;
                    &_img {
                        &_1 {
                            max-height: 18px;
                        }
                        &_2 {
                            max-height: 20px;
                        }
                        &_3 {
                            max-height: 35px;
                        }
                        &_4 {
                            max-height: 20px;
                        }
                        &_5 {
                            max-height: 50px;
                        }
                        &_6 {
                            max-height: 30px;
                        }
                    }
                }
            }
        }
        &_organizer {
            max-width: 100%;
        }
    }
    
}


@media (max-width: 800px) {
    .footer {
        &_organizer {
            flex-direction: column;
        }
        &_info_logo {
            max-width: 400px;
        }
    }
}