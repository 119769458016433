.faq-block {
    padding-top: 50px;
    &_container {
        max-width: 1000px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &_title {
        font-size: 25px;
        font-weight: 700;
    }
}