.program-entertainments_item {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    background: #ffffffb3;
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    &_background {
        position: relative;
        display: block;
        width: 100%;
        min-height: 150px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 15px;
        &_loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #23236B;
            background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(50%, rgba(130, 130, 130, 0.3)), color-stop(100%, rgba(130, 130, 130, 0.2)));
            background: linear-gradient(to left, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 50%, rgba(130, 130, 130, 0.2) 100%);
            animation: wave-lines 6s infinite ease;
            z-index: -1;
        }
        &_img {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    &_name {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: var(--theme-main-ticket-button-text);
    }
}

@media (max-width: 500px) {
    .program-entertainments_item_background {
        min-height: 230px;
    }
}