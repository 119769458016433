.advantages {
    margin-top: 50px;
    &__container {
        max-width: 1520px;
        margin: auto;
        padding: 0 5%;
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
    }
    &__item {
        border-radius: 10px;
        max-width: 620px;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        &__title {
            padding: 40px 40px 0 40px;
            font-weight: 600;
            font-size: 26px;
            line-height: 34px;
            color: var(--theme-main-ticket-button-text);
            margin: 0;
            margin-bottom: 18px;
        }
        &__list {
            padding: 0 40px 0 60px;
            font-weight: 500;
            font-size: 16px;
            color: var(--theme-main-ticket-button-text);
            &__item {
                margin: 4px 0;
            }
        }
        &__photo {
            padding: 0 40px 40px 40px;
            margin: auto 0 0 0;
            img {
                width: 100%;
            }
        }
        &_btn-applic {
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            border-radius: 16px;
            padding: 12px 18px;
            margin: 0 auto;
            margin-bottom: -50px;
            z-index: 2;
            transition: .3s;
            cursor: pointer;
            &_text {
                font-weight: 700;
                font-size: 18px;
                line-height: 21px;
                text-align: center;
                color: #7A1EE1;
            }
        }
        &_btn-applic:hover {
            background: #d3d3d3;
        }
        &_btn-applic:active {
            background: #b6b6b6;
        }
        &__note {
            padding: 0 40px 0 40px;
            font-weight: 600;
            font-size: 16px;
        }
    }
    &__item:nth-child(odd) {
        background: #ffffffb3;
        backdrop-filter: blur(7.5px);
        margin-right: 10px;
    }
    &__item:nth-child(even) {
        background: #ffffffb3;
        backdrop-filter: blur(7.5px);
        margin-left: 10px;
    }
}

@media (max-width: 870px) {
    .advantages {
        &__wrapper {
            flex-direction: column;
        }
        &__item {
            max-width: 100%;
            margin-bottom: 25px;
            &__title {
                font-size: 18px;
                line-height: 22px;
                padding: 20px 20px 0 20px;
                margin-bottom: 10px;
            }
            &__list {
                padding: 0 20px 20px 40px;
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
}