.details {
    position: relative;
    background: #EDEDED;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin-bottom: 20px;
    outline: none;
    &_summary {
        cursor: pointer;
        user-select: none;
        padding: 25px 110px 25px 25px;
        list-style-type: none;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 16px 16px 16px 16px;
        display: flex;
        align-items: center;
        &_icon {
            width: 38px;
            height: 38px;
            background: #007C00;
            border-radius: 10px;
        }
        &_title {
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-left: 10px;
            cursor: pointer;
        }
        
    }
    &_content {
        max-height: 100%;
        overflow: hidden;
        transition: max-height 0.3s ease-in;
        padding: 0 25px;
        &_img {
            width: 100%;
            border-radius: 16px;
            overflow: hidden;
        }
        &_text {
            user-select: none;
            padding-top: 15px;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;

            color: #595959;
            margin-bottom: 20px;
        }
    }   
    &_info {
        display: block;
        font-size: 16px;
        color: #595959;
        font-weight: 600;
        margin-bottom: 20px;
    }
    &_rules {
        font-size: 16px;
        color: #595959;
        
    }
}

.details:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    //background: url(../../img/information/questions/open.svg);
    right: 20px;
    top: 20px;
    transition: transform 0.3s ease;
    cursor: pointer;
}
.details.open:before {
    transform: rotate(45deg);
}