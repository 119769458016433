.alfabank-promo {
    background: #22EAE9 ;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    height: 450px;
    max-width: 1920px;
    width: 100%;
    margin: auto;
    grid-column: 1 / 4;
    grid-row: 3;
    box-shadow:rgba(0, 0, 0, 0.3) 0px 0px 15px;
    &_container {
        max-width: 1519px;
        margin: auto;
        padding: 0 60px;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
    }
    &_logo {
        height: 40px;
        z-index: 2;
        &_img {
            height: 100%;
        }
    }
    &_title {
        &_viva, &_bonus {
            margin: 0;
            font-weight: 700;
            font-size: 68px;
            color: #EF3124;
            max-width: 620px;
            z-index: 2;
            text-transform: uppercase;
        }
        &_viva {
            font-size: 110px;
            margin-left: -20px;
        }
        &_bonus {
            font-size: 150px;
            margin-top: -50px;
            margin-left: 80px;
        }
    }
    &_text {
        margin: 0;
        margin-top: 10px;
        font-weight: 600;
        font-size: 30px;
        line-height: 30px;
        color: #000000;
        max-width: 570px;
        z-index: 2;
        text-transform: uppercase;
    }
    &_btn {
        text-decoration: none;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
        padding: 12px 38px;
        background: #EF3124;
        border-radius: 30px;
        margin: 30px auto 0 0;
        z-index: 2;
        text-transform: uppercase;
    }
    &_card {
        position: absolute;
        top: 10px;
        right: 10px;
        max-width: 581px;
        z-index: 2;
        &_img {
            width: 100%;
        }
    }
    &_graffiti-left {
        display: flex;
        position: absolute;
        bottom: 0;
        left: 0px;
        z-index: 1;
        width: 700px;
        &_img {
            width: 100%;
        }
    }
    &_wave {
        display: flex;
        position: absolute;
        bottom: 0;
        right: 0px;
        z-index: 1;
        width: 1500px;
        &_img {
            width: 100%;
        }
    }
    &_back-text {
        display: flex;
        position: absolute;
        bottom: -120px;
        z-index: 0;
        &_text {
            display: block;
            font-size: 280px;
            font-weight: 700;
            text-transform: uppercase;
            color: #00d0d096;
            max-width: 500px;
            line-height: 220px;
        }
    }
}


@media (max-width: 1280px) {
    .alfabank-promo {
        &_card {
            // right: 25px;
        }
        &_wave {
            right: -100px;
        }
        &_title {
            &_viva {
                font-size: 90px;
                margin-top: 20px;
            }
            &_bonus {
                font-size: 120px;
                margin-top: -30px;
            }
        }
        &_text {
            font-size: 26px;
            line-height: 26px;
        }
    }
}

@media (max-width: 1180px) {
    .alfabank-promo {
        &_card {
            max-width: 455px;
            // transform: rotate(0deg);
            right: 20px;
            top: 40px;
        }
        &_wave {
            right: -350px;
        }
    }
}

@media (max-width: 1024px) {
    .alfabank-promo {
        &_title {
            &_viva {
                font-size: 60px;
                margin-top: 20px;
            }
            &_bonus {
                font-size: 90px;
                margin-top: -30px;
            }
        }
        &_text {
            font-size: 26px;
            line-height: 26px;
            max-width: 290px;
            margin-top: 20px;
        }
        &_card {
            top: 100px;
        }
    }
}

@media (max-width: 957px) {
    .alfabank-promo {
        background-position-y: center;
        background-position-x: 00%;
        
       
    }
}

@media(max-width: 870px) {
    .alfabank-promo {
        grid-column: 1;
        grid-row: 6;
    }
}

@media (max-width: 800px) {
    .alfabank-promo {
        &_wrapper {
            // align-items: center;
        }
        &_logo {
            height: 35px;
            max-width: 290px;
            width: 100%;
        }
        &_title {
            &_viva {
                margin-top: 32px;
                font-size: 45px;
                line-height: 56px;
            }
            &_bonus {
                margin-top: -20px;
                margin-left: 50px;
                font-size: 60px;
            }
            
        }
        &_btn {
            line-height: 1;
            font-size: 16px;
            padding: 12px 18px;
        }
        &_text {
            font-size: 20px;
            line-height: 26px;
            // max-width: 100%;
            margin-top: 20px;
        }
        &_card {
            // position:inherit;
            max-width: 380px;
            // margin-top: 20px;
            order: 3;
        }
        &_graffiti-left {
            width: 500px;
        }
        &_wave {
            right: -450px;
        }
    }
}

@media (max-width: 680px) {
    .alfabank-promo {
        &_container {
            padding: 0 20px;
        }
        &_title {
            &_viva {
                margin-top: 12px;
                font-size: 45px;
                line-height: 56px;
                margin-left: 20px;
            }
            &_bonus {
                margin-top: -20px;
                margin-left: 70px;
                font-size: 70px;
            }
            
        }
        &_text {
            font-size: 16px;
            line-height: 1;
            margin-left: 20px;
        }
        &_btn {
            margin-left: 20px;
        }
        &_card {
            max-width: 300px;
            margin-top: 120px;
        }
    }
}


@media (max-width: 480px) {
    .alfabank-promo {
        &_title {
            &_viva {
                margin-top: 20px;
                font-size: 55px;
                line-height: 1;
                margin-left: 20px;
            }
            &_bonus {
                margin-top: -15px;
                margin-left: 70px;
                font-size: 70px;
            }
            
        }
        &_text {
            font-size: 16px;
            line-height: 1;
            margin-left: 20px;
            margin-top: 5px;
        }
        &_btn {
            font-size: 14px;
            margin-top: 20px;
        }
        &_card {
            max-width: 300px;
            margin-top: 120px;
        }
        &_wave {
            right: -500px;
        }
        &_graffiti-left {
            width: 300px;
        }
    }
}