
.contracts {
    &__container {
        max-width: 600px;
        margin: auto;
        padding: 0 10px;
    }
    &__wrapper {
        margin-top: 60px;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
    }
    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        background: #FFFFFF;
        border-radius: 16px;
        padding: 15px;
        &_right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &_btns {
                height: 40px;
                display: flex;
            }
            &_title {
                text-align: center;
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                color: #000000;
                margin-bottom: 10px;
                width: 100%;
                &_disable {
                    color: #7b7b7b;
                }
            }
            &_text {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
                margin-bottom: 15px;
            }
            &_btn {
                min-width: 120px;
                padding: 12px 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                background: var(--theme-main-ticket-button);
                border-radius: 10px;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: var(--theme-main-ticket-button-text);
                text-decoration: none;
                margin: 0px 10px 0 0;
                cursor: pointer;
                transition: .2s ease;
                -webkit-appearance: none;
            }
            &_btn:hover {
                background: var(--theme-main-ticket-button-hover);
            }
            &_btn:active {
                background: var(--theme-main-ticket-button-active);
            }
        }
        
    }
}

@media (max-width: 849px) {
    .contracts {
        &__item {
            &_left {
                align-items: flex-start;
                min-width: 50px;
                &_count {
                    left: 0;
                    bottom: -32px;
                    font-size: 70px;
                }
                &_img {
                    display: none;
                }
            }
        }
    }
}

.modal-route {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: scroll; /* Enable horizontal scrolling */
    width: 100%;

    img {
      display: flex;
      width: 100%;
      
    }
}

// .css-ypiqx9-MuiDialogContent-root, .css-1ty026z {
//     padding: 0 !important;
// }

.css-12rl710-MuiPaper-root-MuiDialog-paper, .css-1qmc5dd {
    margin: 0 !important;
}

.zoom-animation {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    animation: fadeOut 2.5s 1;
    transition: all 0.3s ease-in-out;
}

@keyframes fadeOut {
    0%, 
    90% {
        background: #1313137b;
    }
    100% {
        background: #13131300;
    }
  }
  

@media(max-width: 768px) {
    .zoom-animation {
        display: block;
    }

    .contracts {
        &__item {
            &_right {
                &_title {
                    max-width: 300px;
                }
            }
        }
    }
}  