.loader-page {
  height: 100vh;
  background: #001800;
  display: flex;
  justify-content: center;
  align-items: center;
}

.miss {
    padding-top: 70px;
    overflow: hidden;
    &_container {
        margin: auto;
        // padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &_girls {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        &_miss, &_vb, &_20, &_24 {
            font-family: "Rubik", sans-serif;
            font-optical-sizing: auto;
            font-style: normal;
            font-weight: bold;
        }
        &_wrapper-year {
            position: absolute;
            display: flex;
            justify-content: space-between;
            max-width: 1100px;
            width: 100%;
            height: 400px;
            top: 250px;
        }
        &_20, &_24 {
            color: rgba(255, 255, 255, 0.80);
            font-size: 180px;
            line-height: normal;
        }
        &_20 {
            left: 160px;
        }
        &_24 {
            right: 160px;
        }

        &_miss {
            color: #D3FF02;
            text-shadow: 0px 2px 2px #D3FF02;
            font-size: 120px;
            line-height: normal;
            text-transform: uppercase;
        }
        &_vb {
            color: #FFF;
            font-size: 80px;
            line-height: normal;
            text-transform: uppercase;
        }
        &_wrapper {
            margin-top: 100px;
            display: flex;
            justify-content: space-between;
            height: 400px;
            margin-left: -50px;
        }
        &_1, &_2, &_3 {
            display: flex;
            max-width: 300px; 
            width: 100%; 
            height: 100%;
            border-radius: 50%; 
            filter: drop-shadow(0px 2px 2px #d3ff02) drop-shadow(2px 0px 2px #d3ff02) drop-shadow(0px -2px 2px #d3ff02) drop-shadow(-2px 0px 2px #d3ff02);
        }
        &_1 {
            z-index: 1;
            margin-right: -120px;
        }
        &_2 {
            z-index: 3;
        }
        &_3 {
            z-index: 1;
            margin-left: -50px;
        }
        &_bg {
            z-index: 2;
            position: absolute;
            display: block;
            height: 500px;
            left: 0;
            right: 0;
            background: linear-gradient(180deg, var(--theme-bg) 17.5%, var(--theme-bg) 86%);
        }
        &_apply {
            position: absolute;
            top: 330px;
            border-radius: 10px;
            background: #7e7e7e;
            padding: 12px 18px;
            text-decoration: none;
            z-index: 4;
            &_text {
                font-size: 16px;
                font-weight: 600;
                color: #e5e5e5;
            }
        }
        
    }
    &_information {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #D3FF02;
        border-radius: 40px;
        max-width: 1230px;
        width: 100%;
        padding: 18px 12px;
        z-index: 4;
        &_text {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #090033;
            margin-bottom: 20px;
            max-width: 670px;
        }
        &_primary-text {
            font-weight: 700;
            font-size: 25px;
            line-height: 30px;
            text-align: center;
            color: #090033;
        }
    }
}

.miss-partners {
    margin: auto;
    margin-top: 50px;
    width: 100%;
    max-width: 1519px;
    overflow: hidden;
    padding: 0 10px;
    &_slide {
        width: 195px;
        max-height: 120px;
        overflow: hidden;
        display: flex;
        align-items: start;
        justify-content: center;
        img {

            // width: 100%;
            height: 100%;
            object-fit: cover;
        } 
    }
    &_swiper {
        border-radius: 16px;
    }
}

.miss-apply-btn-mobile {
    display: none;
    background: #515151;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
    padding: 12px 18px;
    text-decoration: none;
    margin: 20px 10px 0 10px;
    // background-image: linear-gradient(
    //     270deg,
    //     rgb(239, 144, 217, 0) 48.44%,
    //     rgb(239, 144, 217, 50) 75.52%,
    //     rgb(239, 144, 217, 0) 100%
    // );
    background-repeat: no-repeat;
    // animation: bg-move linear 3s infinite;
    transition: 0.3s ease;
}

.miss-apply:hover {
    background: #515151;
}

.miss-apply:active {
    background: #515151;
}

@keyframes bg-move {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}

@keyframes sunlight {
    0% {
        box-shadow: 0 0 0 0 #ff9a03;
    }
    40% {
        box-shadow: 0 0 0 100px transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

.miss-how {
    margin: 100px 0;
    overflow: hidden;
    &_yellow {
        color: #D3FF02;
    }
    &_container {
        max-width: 1200px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        justify-content: space-between;
    }
    &_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 67px;
    }
    &_item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 277px;
        &_number {
            font-weight: 700;
            font-size: 40px;
            line-height: 47px;
            text-align: center;
            color: #ffffff;
        }
        &_text {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
        }
    }
    &_info {
        margin-top: 80px;
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        text-align: center;
    }
}

.miss-gallery {
    overflow: hidden;
    max-width: 1519px;
    margin: auto;
    padding: 0 10px;
    &_slide {
        height: 550px;
        overflow: hidden;
        border-radius: 16px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &_swiper {
        border-radius: 16px;
    }
}

.miss-why {
    margin-top: 100px;
    &_container {
        max-width: 1070px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        justify-content: space-between;
    }
    &_title {
        max-width: 300px;
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        color: #ffffff;
    }
    &_arguments {
        display: flex;
        justify-content: space-between;
        max-width: 690px;
        width: 100%;
        &_part {
            list-style-type: none;
            margin: 0;
            padding: 0;
            &_item {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: #ffffff;
            }
            &_item:before {
                content: "— ";
                color: var(--theme-bg);
            }
        }
    }
    &_info {
        padding-top: 70px;
        display: block;
        margin: 0 auto;
        max-width: 600px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
    }
}

.miss-bg {
    overflow: hidden;
    margin-top: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    //will-change: auto;
    // img {
    //     height: 350px;
    // }
    &_block {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        top: 50%;
        bottom: 50%;
    }
    &_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        color: #ffffff;
        margin-bottom: 10px;
        text-align: center;
    }
    &_year {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        margin-bottom: 20px;
    }
    &_btn {
        background: #D3FF02;
        border-radius: 10px;
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: #2a2a2a;
        padding: 12px 18px;
        text-decoration: none;
    }
}

.miss-info {
    margin-bottom: 70px;
    &_container {
        max-width: 840px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &_text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 70px;
    }
    &_instagram {
        display: flex;
        flex-direction: column;
        align-items: center;
        &_primary {
            font-weight: 700;
            font-size: 25px;
            line-height: 30px;
            color: #ffffff;
            margin-bottom: 10px;
            text-align: center;
        }
        &_secondary {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 5px;
            text-align: center;
        }
        &_link {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #D3FF02;
            text-decoration: none;
        }
    }
}

.insta {
    overflow: hidden;
    max-width: 1200px;
    margin: auto;
    padding: 0 10px;
    &_swiper {
        border-radius: 10px;
    }
    &_title {
        font-style: normal;
        font-weight: 400;
        font-size: calc(18px + 6 * (100vw / 1280));
        color: #ffffff;
        margin: 60px 0 15px 0;
    }
    &_container {
        max-width: 1200px;
        display: inline-flex;
        margin: 10px auto;
        flex-direction: row;
        height: 390px;
    }
    &_slide {
        border-radius: 10px;
        overflow: hidden;
        margin-right: 16px;
        text-decoration: none !important;
        cursor: pointer;
        &_head {
            display: flex;
            align-items: center;
            padding: 8px 10px;
            background: #ffffff;
            &_texts {
                display: flex;
                flex-direction: column;
                margin-left: 10px;
            }
            &_nickname {
                font-family: "Museo Sans", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 14px;
                letter-spacing: -0.1px;
                color: #262626;
                margin: 0;
                display: flex;
                align-items: center;
                text-decoration: none;
            }
            &_nickname:hover {
                text-decoration: underline;
            }
            &_nickname::after {
                display: block;
                width: 12px;
                height: 12px;
                content: "";
                background: url(https://vivabraslav.by/img/insta/official.svg)
                    no-repeat center center;
                background-size: cover;
                margin-left: 3px;
            }
            &_geo {
                font-family: "Museo Sans", sans-serif;
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                line-height: 12px;
                letter-spacing: 0.07px;
                color: #262626;
                margin: 0;
            }
            &_logo {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                border: 0.5px solid rgba(0, 0, 0, 0.1);
                background: url('../../img/miss/logoinsta24.jpg')
                    center center;
                background-size: cover;
            }
            &_btn {
                margin-right: 0;
                margin-left: auto;
            }
        }
        &_photo {
            background-size: cover;
            height: 295px;
            cursor: pointer;
        }
        &_footer {
            padding: 11.5px 14px;
            display: flex;
            justify-content: space-between;
            background: #ffffff;
            &_left,
            &_right {
                display: flex;
                align-items: center;
            }
            &_like {
                background: url(https://vivabraslav.by/img/insta/like.svg);
                margin-right: 7px;
            }
            &_comment {
                background: url(https://vivabraslav.by/img/insta/comment.svg);
                margin-right: 7px;
            }
            &_direct {
                background: url(https://vivabraslav.by/img/insta/direct.svg);
            }
            &_save {
                background: url(https://vivabraslav.by/img/insta/save.svg);
            }
            &_dots {
                background: url(https://vivabraslav.by/img/insta/dots.svg);
            }
            &_like,
            &_comment,
            &_direct,
            &_save,
            &_dots {
                border: none;
                background-size: cover;
                width: 23px;
                height: 23px;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
    &_end {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        height: 389px;
        &_logo {
            background: url(https://vivabraslav.by/img/insta/oval.svg) no-repeat;
            width: 99px;
            height: 99px;
            background-size: contain;
            display: flex;
            justify-content: center;
            align-items: center;
            &_img {
                width: 86px;
                height: 86px;
                background: url('../../img/miss/logoinsta24.jpg')
                    no-repeat center center;
                background-size: cover;
                border-radius: 50%;
            }
        }
        &_link {
            font-family: "Museo Sans", sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 17px;
            color: #ffffff;
            background: #3797ef;
            border-radius: 5px;
            padding: 11px 29px;
            text-decoration: none;
        }
        &_nickname {
            font-family: "Museo Sans", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: -0.15px;
            color: #262626;
            margin-top: 13px;
            margin-bottom: 31px;
            text-decoration: none !important;
        }
    }
}

@keyframes bg-move {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}

@media (max-width: 1040px) {
    .miss-why {
        &_wrapper {
            justify-content: space-around;
        }
        &_title {
            max-width: 50%;
        }
        &_arguments {
            max-width: 50%;
            flex-direction: column;
        }
    }
}

@media (max-width: 810px) {
    .miss {
        padding-top: 110px;
        &_girls {
            &_wrapper-year {
                display: none;
            }
            &_miss {
                font-size: 80px;
            }
            &_vb {
                font-size: 62px;
            }
            &_apply {
                position: absolute;
                top: 300px;
                &_text {
                    font-size: 16px;
                    font-weight: 600;
                    color: #001800;
                }
            }
        }
    }
}

@media (max-width: 510px) {
    .miss {
        padding-top: 90px;
        &_girls {
            &_wrapper-year {
                display: none;
            }
            &_miss {
                font-size: 60px;
            }
            &_vb {
                font-size: 32px;
            }
            &_apply {
                position: absolute;
                top: 220px;
                &_text {
                    font-size: 16px;
                    font-weight: 600;
                    color: #001800;
                }
            }
            &_wrapper {
                position: relative;
                display: block;
                margin-left: 0;
                width: 100%;
                margin-top: 60px;
            }
            &_1, &_2, &_3 {
                position: absolute;
                display: block;
                img {
                    height: 100%;
                    
                }
            }
            &_1 {
                left: -60px;
                height: 330px;
            }
            &_2 {
                left: 30px;
                bottom: -45px;
                height: 400px;
            }
            &_3 {
                height: 330px;
                right: -50px;
            }
            &_bg {
                height: 450px;
                bottom: 0;
                background: linear-gradient(180deg, rgba(17, 182, 44, 0.00) 17.5%, #001800 84%);
            }
        }
    }
}

@media (max-width: 450px) {
    .miss {
        padding-top: 90px;
        &_girls {
            &_1 {
                left: -60px;
                height: 330px;
            }
            &_2 {
                left: 15px;
                bottom: -45px;
            }
            &_3 {
                height: 330px;
                right: -80px;
            }
        }
    }
}

@media (max-width: 380px) {
    .miss {
        padding-top: 90px;
        &_girls {
            &_1 {
                left: -80px;
                height: 330px;
            }
            &_2 {
                left: -25px;
                bottom: -45px;
            }
            &_3 {
                height: 330px;
                right: -80px;
            }
        }
    }
}

@media (max-width: 780px) {
    .miss-apply-btn-mobile {
        display: block;
    }
    .miss {
        &_girl {
            &_background {
                height: 420px;
                &_1 {
                    width: 700px;
                    height: 450px;
                    top: 50px;
                }
                &_2 {
                    width: 530px;
                    height: 247px;
                    top: 200px;
                }
                &_3 {
                    width: 320px;
                    height: 374px;
                }
                &_logo {
                    right: -270px;
                    width: 464px;
                }
            }
            &_partner {
                right: -300px;
                top: 250px;

                &_logo {
                    width: 50px;
                    height: 50px;
                }
            }
            &_btn {
                display: none;
            }
        }
    }
    .miss-how {
        &_title {
            font-size: 24px;
            line-height: 28px;
        }
        &_wrapper {
            flex-direction: column;
            align-items: center;
        }
        &_item {
            align-items: center;
            margin-bottom: 50px;
            &_text {
                text-align: center;
            }
        }
        &_info {
            margin-top: 10px;
        }
    }
}

@media (max-width: 660px) {
    .miss-why {
        &_title {
            text-align: center;
            max-width: 100%;
        }
        &_wrapper {
            flex-direction: column;
            align-items: center;
        }
        &_arguments {
            max-width: 100%;
            margin-top: 40px;
        }
    }
    .miss-bg {
        &_title {
            font-size: 26px;
            line-height: 31px;
        }
    }
    .miss {
        &_information {
            border-radius: 0px 0px 16px 16px;
        }
    }
}

@media (max-width: 590px) {
    .miss {
        &_girl {
            &_background {
                height: 400px;
                &_1 {
                    width: 600px;
                    height: 400px;
                    top: 100px;
                }
                &_2 {
                    width: 300px;
                    height: 157px;
                    top: 250px;
                }
                &_3 {
                    width: 270px;
                    height: 374px;
                    z-index: 2;
                }
                &_logo {
                    top: 50px;
                    right: -180px;
                    width: 350px;
                    background: url(../../img/miss/logo-mobile24.png);
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
            &_partner {
                right: -180px;
                top: 200px;
            }
        }
    }

    .miss-gallery {
        &_slide {
            height: 450px;
        }
    }
}
