.open-bonus {
    .modal-bonus {
        pointer-events: all;
        opacity: 1;
        &_container {
            transform: translateY(10%);
        }
    }
}

.modal-bonus {
    z-index: 9997;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.15s ease-in;
    will-change: all;
    &_bg {
        background: #000000bd; 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &_close {
        position: absolute;
        cursor: pointer;
        right: 10px;
        top: 17px
    }
    &_container {
        will-change: transform;
        z-index: 3;
        transition: transform 0.44s cubic-bezier(0.54, 0.04, 0, 1.36);
        transform: translateY(100%);
        max-width: 400px;
        max-height: 420px;
        border-radius: 16px 16px 0 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto auto 0 auto;
    }
    &_scroll {
        background: #fff;
        border-radius: 0 0 16px 16px;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        transition: transform .3s all;
        background: #fff;
    }
    &_wrapper::-webkit-scrollbar {
        width: 0;
    }
    &_text {
        padding: 20px 16px;
        text-align: justify;
    }
}   

.shine {
    background-image: linear-gradient(270deg, rgba(100, 239, 100, 0) 48.44%, #ffffff96 75.52%, rgba(100, 239, 132, 0) 100%);
    background-repeat: no-repeat;
    animation: bg-move linear 5s infinite;
    will-change: transform;
}

@keyframes bg-move {
    0% {
        background-position: -500px 0;
    }
        
    100% {
        background-position: 1000px 0;
    }    
}



@media (max-width: 500px) {
    .modal-more {
        &_container {
            transition: transform 0.55s cubic-bezier(0.55, 0.04, 0, 1.36);
            margin-bottom: 0;
            border-radius: 16px 16px 0 0;
            // min-height: 100vh; 
            display: flex;
            flex-direction: column;
        }
        &_scroll {
            padding-bottom: 0px;
        }
        &::after {
            content: "";
            display: block;
            height: 50px; /* Adjust the height as necessary to cover the gap */
            background: #fff; /* Match the background color of the modal */
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &_wrapper {
            background: #fff;
            border-radius: 16px 16px 0 0;
            flex-grow: 1;
           // padding-bottom: 20px;
        }
    }
}


@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}