.application-modal {
    &_header {
        width: 100%;
        height: 27px;
        background: var(--theme-main-application-button);
        position: fixed;
        border-radius: 16px 16px 0 0;
        z-index: 999;
    }
    &_wave {
        position: absolute;
        top: 6px;
        right: 32px;
        display: block;
    }
    &_close {
        position: absolute;
        right: 40px;
        top: 15px;
        display: block;
        width: 25px;
        height: 25px;
        background: url(../../../../img/tickets/close-white.svg);
        z-index: 3;
        cursor: pointer;
    }
    &_title {
        margin-top: 47px;
        margin-left: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
}
