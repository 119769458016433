h1, h2, h3, h4, h5, h6, span, p, div {
    user-select: none;
}

        


.tickets {
    position: relative;
    // overflow: hidden;
    &_container {
        max-width: 872px;
        margin: auto;
        padding: 0 10px;
        z-index: 1;
    }
    &_loader {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &_category {
        padding-bottom: 25px;
        &_title {
          // position: sticky;
          // top: -15px;
          z-index: 1;
          font-weight: 600;
        //   font-size: calc(16px + 8 * (100vw / 1280));
        font-size: 22px;
          text-align: center;
          color: var(--theme-main-ticket-button-text);
          margin: 0;
          margin-bottom: 15px;
            &::after {
            display: block;
            content: '';
            width: 95%;
            margin: 0 auto;
            height: 2.5px;
            margin-top: 10px;
            background: var(--theme-headerline);
            border-radius: 10px;
            box-shadow: 0 0 10px var(--theme-headerline), 0 0 20px var(--theme-headerline), 0 0 30px var(--theme-headerline), 0 0 40px var(--theme-headerline);
            }
        }
      }

    &_additionals {
      display: flex;
      align-items: center;
      justify-content: center;
      &_button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 600;
        color: var(--theme-main-ticket-button-text);
        background: var(--theme-main-ticket-button);
        border-radius: 5px;
        transition: 0.3s;
        margin: 0 10px;
        text-decoration: none;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        text-align: center;
        min-width: 208px;
        &:hover {
          background: var(--theme-main-ticket-button-hover);
        }
      }
    }
}

@media (max-width: 500px) {
  .tickets {
    &_additionals {
      margin-top: 20px;
      flex-direction: column;
      &_button {
        width: 100%;
        margin-bottom: 15px;
        border-radius: 16px;
      }
    }
  }
}



  