.forbidden {
    padding-top: 125px;
    height: 100vh;
    &_container {
        max-width: 1200px;
        margin: auto;
        padding: 0 10px;
        height: 100%;
    }
    &_wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &_title {
        text-align: center;
        font-weight: 700;
        color: #fff;
        margin: 0;
        font-size: 24px;
    }
    &_text {
        text-align: center;
        grid-auto-flow: 500px;
        color: #fff;
        font-size: 18px;
    }
}