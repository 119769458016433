.important {
    margin-top: 70px;
    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        color: var(--theme-main-ticket-button-text);
        margin: 0;
        margin-bottom: 30px;
    }
    &__container {
        max-width: 860px;
        margin: auto;
        padding: 0 5%;
    }
    &__list {
        padding: 0;
        padding-left: 5%;
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        color: var(--theme-main-ticket-button-text);
        &__item {
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 768px) {
    .important {
        &__list {
            font-size: 16px;
            line-height: 18px;
        }
    }
}