*, *::before, *::after {
  box-sizing: border-box;
}




.body-lock {
  overflow: hidden;
  width: 100%; /* Предотвращает возможный сдвиг содержимого при скрытии скроллбара */
}
