.applications_item {
    background: #ffffffb3;
    backdrop-filter: blur(7.5px);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 20px;
    display: flex;
    &_icon {
        min-width: 65px;
        height: 65px;
        border-radius: 50%;
        background: var(--theme-main-application-button);
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &_info {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 5px;
        &_title {
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
            color: #000000;
        }
        &_description {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #383839;
            margin-top: 10px;
            margin-bottom: 25px;
        }
        &_btn-active, &_btn-disable {
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #FFFFFF;
            width: 147px;
            height: 43px;   
            display: flex;
            align-items: center;
            justify-content: center;   
            border-radius: 10px; 
            margin: 0 0 0 auto;
        }
        &_btn-active {
            background: var(--theme-main-application-button);
            cursor: pointer;
            transition: .2s ease;
        }
        &_btn-active:hover {
            background: var(--theme-main-application-button-hover);
        }
        &_btn-active:active {
            background: var(--theme-main-application-button-active);
        }
        &_btn-disable {
            background: #818181;
            cursor: not-allowed;
        }
    }
}

@media (max-width: 500px) {
    .applications_item {
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
        &_icon {
            margin-right: 0;
            width: 65px;
        }
        &_info {
            &_title, &_description {
                text-align: center;
            }
            &_btn-active, &_btn-disable { 
                margin: 0 auto;
            }
        }
    }
}