.program-artists_item {
    min-height: 250px;
    width: 100%;
    cursor: pointer !important;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    will-change: all;
    
    &_background {
        &_img {
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: transform 2.3s ease-in-out;
        }
        &_loader {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 1;
        }
        &_blur {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.218113) 78.12%, rgba(0, 0, 0, 0.34) 82.81%, #000000 100%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 3;
        }
    }
    
    &_name {
        position: absolute;
        display: block;
        bottom: 0;
        left: 0;
        z-index: 3;
        margin-bottom: 15px;
        margin-left: 25px;
        display: flex;
        align-items: center;
        
        &_text {
            font-weight: 600;
            font-size: 25px;
            line-height: 29px;
            color: #FFFFFF;
            max-width: 250px;
            border-bottom: 3px solid var(--theme-main-ticket-button);
        }
        
        &_arrow {
            display: inline-block;
            width: 22px;
            height: 23px;
            background: url(../../../img/program/artists/arrow.svg);
            background-size: cover;
            background-position: center;
            margin-left: 15px;
        }
    }
}

.program-artists_item:hover .program-artists_item_background_img {
    transform: scale(1.3);
}

/* Disable zoom on mobile devices */
@media (max-width: 768px) {
    .program-artists_item:hover .program-artists_item_background_img {
        transform: none;
    }
}
