.applications {
    
    &_container {
        max-width: 756px;
        margin: auto;
        padding: 0 10px;
    }
}

.open-form {
    .application-modal {
        pointer-events: all;
        opacity: 1;
        &_container {
            transform: translateY(0%);
        }
    }
}
