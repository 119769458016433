.loader-program {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--theme-background);
    z-index: 999;
    transition: opacity 0.25s ease-in-out;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    &_loaded {
        opacity: 0;
        z-index: -1 !important;
    }
    &_image {
        width: 300px;
        animation: logo-loader linear 1 0.9s infinite;
        &_img {
            width: 100%;
        }
    }
}

@keyframes logo-loader {
    0% {
        opacity: 0.8;
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 0.8;
    }
}

.program-subtitle {
    font-weight: 600;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    color: var(--theme-main-ticket-button-text);
    margin: 0;
    margin-bottom: 45px;
}

.program-header {
    padding-top: 140px;
    max-width: 1519px;
    margin: auto;
    &_container {
        max-width: 1920px;
        margin: auto;
        padding: 0 40px;
    }
    &_wrapper {
        background: 
        url(../../img/program/header.jpg);
        background-size: cover;
        background-position: center;
        min-height: 252px;
        border-radius: 20px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 30px;
        padding: 0 30px 30px 80px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    }
    &_title {
        font-weight: 600;
        font-size: 45px;
        line-height: 53px;
        color: #ececec;
        margin: 0;
    }
    &_info {
        &_item {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            &_icon {
                margin-right: 7px;
            }
            &_rows {
                display: flex;
                flex-direction: column;
            }
            &_row {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                color: #ececec;
            }
        }
    }
}

.program-sport {
    padding-top: 30px;
    // background-color: var(--theme-background);
    &_container {
        max-width: 1350px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        gap: 30px;
    }
}

.program-entertainments {
    margin-top: 75px;
    padding-top: 55px;
    // background: linear-gradient(180deg, var(--theme-background) 0%, var(--theme-background) 100%);
    will-change: all;
    &_container {
        max-width: 1390px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        gap: 30px;
    }
}

.program-artists {
    &_container {
        max-width: 1390px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
        gap: 30px;
        will-change: all;
    }
}

.open-modal-appl {
    .application-modal {
        pointer-events: all;
        opacity: 1;
        &_container {
            transform: translateY(0%);
        }
    }
}

.open-modal {
    .program-modal {
        pointer-events: all;
        opacity: 1;
        &_container {
            transform: translateY(0%);
        }
    }
}

.program-modal {
    z-index: 9997;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.15s ease-in;
    will-change: all;
    &_bg {
        background: #000000bd;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &_container {
        will-change: transform;
        z-index: 3;
        transition: transform 0.45s cubic-bezier(0.54, 0.04, 0, 1.36);
        transform: translateY(100%);
        max-width: 380px;
        max-height: 455px;
        border-radius: 16px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    &_header {
        background: var(--theme-main-ticket-button);
        width: 100%;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
    }
    &_header:hover {
        background: var(--theme-main-ticket-button-hover);
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        transition: transform .3s all;
    }
    &_wrapper::-webkit-scrollbar {
        width: 0;
    }
    &_scroll {
        padding-bottom: 20px;
        background: #fff;
        border-radius: 0 0 16px 16px;

    }
    &_top {
        display: flex;
        align-items: center;
        padding: 64px 20px 20px 20px;
        &_avatar {
            display: block;
            min-width: 80px;
            height: 80px;
            border-radius: 50%;
            background: #12045c;
            margin-right: 17px;
        }
        &_info {
            display: flex;
            flex-direction: column;
            
            &_title {
                font-weight: 600;
                font-size: 18px;
                line-height: 21px;
                /* identical to box height */

                color: #000000;
            }
            &_short {
                font-weight: 400;
                font-size: 14px;
                line-height: 16px;

                color: #000000;
            }
            &_artists {
                list-style-type: none;
                padding-left: 0;
            }
            &_artists li {
                position: relative;
                padding-left: 1.5em;
            }
            &_artists li::before {
                content: "—"; 
                position: absolute;
                left: 0;
                color: black; 
            }
        }
    }
    &_description {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 0px 20px;
        &_name {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #000000;
            margin-bottom: 10px;
        }
        &_text {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            text-align: justify;
        }
    }
    &_organizer {
        display: flex;
        flex-direction: column;
        padding: 0px 20px 20px 20px;
        &_name {
            font-weight: 500;
            font-size: 12px;
            color: #000000;
            margin-bottom: 5px;
        }
        &_text {
            font-weight: 400;
            font-size: 10px;
            color: #000000;
            text-align: justify;
        }
    }
}

.program-more {
    // background: var(--theme-background);
    padding-bottom: 50px;
    &_wrapper {
        max-width: 250px;
        margin: auto;
    }
    &_title {
        font-weight: 600;
        font-size: 30px;
        text-align: center;
        color: var(--theme-main-ticket-button-text);
        margin-bottom: 15px;
    }
    &_subtitle {
        font-size: 18px;
        text-align: center;
        color: var(--theme-main-ticket-button-text);
    }
    &_text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -50px;
    }
}

@media (max-width: 500px) {
    .program-modal {
        &_container {
            transition: transform 0.4s cubic-bezier(0.54, 0.04, 0, 1.36);
            margin-bottom: 0;
            border-radius: 16px 16px 0 0;
        }
        &_scroll {
            padding-bottom: 0px;
        }
        &_wrapper {
            background: #fff;
            border-radius: 16px 16px 0 0;
        }
    }

    .program-sport {
        &_wrapper {
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }
    }
}

@media (max-width: 450px) {
    .program-artists, .program-entertainments {
        &_wrapper {
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }
    }
}

@media (max-width: 1200px) {
    .program-header {
        &_wrapper {
            padding: 0 10px 10px 30px;
        }
    }
}

@media (max-width: 920px) {
    .program-header {
        &_title {
            margin-bottom: 30px;
            font-weight: 500;
        }
        &_info {
            justify-content: center;
        }
        &_wrapper {
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            padding: 0;
        }
    }
}

@media (max-width: 650px) {
    .program-header {
        padding-top: 100px;
        &_container {
            padding: 0 20px;
        }
        &_wrapper {
            background: url(../../img/program//header-mobile.png) !important;
            background-size: cover !important;
            background-position: center;
        }
    }
}

@media (max-width: 500px) {
    .program-header {
        &_title {
            font-size: 30px;
        }
        // &_wrapper {
        //     align-items: flex-start;
        // }
        &_info_item {
            margin-bottom: 10px;
        }
        &_info_item_rows {
           // max-width: 170px;
        }
        &_info_item_row {
            font-size: 14px;
            
        }
    }
    .program-more {
        margin-top: 50px;
        &_wrapper {
            max-width: 180px;
        }
        &_title {
            font-size: 22px;
        }
        &_subtitle {
            font-size: 14px;
        }
    }
}