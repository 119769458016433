.program-sport_item {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
    justify-content: space-between;
    background: #ffffffb3;
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    &_background {
        position: relative;
        display: flex;
        border-radius: 10px;
        overflow: hidden;
        will-change: all;
        width: 45%;
        height: 200px;
        &_loader {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #23236B;
            background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(50%, rgba(130, 130, 130, 0.3)), color-stop(100%, rgba(130, 130, 130, 0.2)));
            background: linear-gradient(to left, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 50%, rgba(130, 130, 130, 0.2) 100%);
            animation: wave-lines 6s infinite ease;
            z-index: -1;
        }
    }
    &_bg {
        display: block;
        width: 100%;
        height: 100%;
    }
    &_info {
        display: flex;
        flex-direction: column;
        width: 50%;
    }
    &_title {
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: var(---theme-main-ticket-button-text);
        margin-bottom: 7px;
    }
    &_description {
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        color: var(---theme-main-ticket-button-text);;
        margin-bottom: 30px;
    }
    &_btn {
        background: #818181;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 45px;
        font-weight: 600;
        font-size: 16px;
        text-align: center;
        color: #FFFFFF; 
        cursor: not-allowed;
    }
    &_btn-active, &_btn-disable {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
        width: 147px;
        height: 43px;   
        display: flex;
        align-items: center;
        justify-content: center;   
        border-radius: 10px; 
    }
    &_btn-active {
        background: var(--theme-main-application-button);
        cursor: pointer;
        transition: .2s ease;
    }
    &_btn-active:hover {
        background: var(--theme-main-application-button-hover);
    }
    &_btn-active:active {
        background: var(--theme-main-application-button-active);
    }
    &_btn-disable {
        background: #818181;
        cursor: not-allowed;
    }
}

@media (max-width: 500px) {
    .program-sport_item {
        &_bg {
            background-position-x: 55% !important;
        }
        &_background {
            width: 25%;
        }
        &_info {
            width: 70%;
        }
    }
}