:root {
    --swiper-theme-color: #ffffff;
    --swiper-pagination-color: #ffffff !important;
    --swiper-navigation-size: 25px;
}

.swiper-button-prev {
   background: url(../../../img/tickets/swiper-button-prev.svg);
   background-position: center;
   background-repeat: no-repeat;
   color: transparent;
   will-change: auto;
   -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.swiper-button-next {
    background: url(../../../img/tickets/swiper-button-next.svg);
    background-position: center;
    background-repeat: no-repeat;
    color: transparent;
    will-change: auto;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
 }

.open-more {
    .modal-more {
        pointer-events: all;
        opacity: 1;
        &_container {
            transform: translateY(0%);
        }
    }
}

.modal-more {
    z-index: 9997;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.15s ease-in;
    will-change: all;
    &_bg {
        background: #000000bd; 
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &_close {
        position: absolute;
        cursor: pointer;
        right: 10px;
        top: 17px
    }
    &_container {
        will-change: transform;
        z-index: 3;
        transition: transform 0.54s cubic-bezier(0.54, 0.04, 0, 1.36);
        transform: translateY(100%);
        max-width: 500px;
        max-height: 520px;
        border-radius: 16px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    &_scroll {
        background: #fff;
        border-radius: 0 0 16px 16px;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-y: auto;
        transition: transform .3s all;
    }
    &_wrapper::-webkit-scrollbar {
        width: 0;
      }
    &_header {
        width: 100%;
        height: 27px;
        background: #4EC87F;
        position: fixed;
        border-radius: 16px 16px 0 0;
    }
    &_wave {
        position: absolute;
        top: 6px;
        right: 32px;
        display: block;
    }
    &_close {
        position: absolute;
        right: 40px;
        top: 15px;
        display: block;
        width: 25px;
        height: 25px;
        background: url(../../../img/tickets/close-white.svg);
        z-index: 3;
        cursor: pointer;
    }
    &_title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        padding: 37px 20px 0 20px;
    }
    &_incldue-text {
        padding: 0 20px 0 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
    }
    &_btn {
        background: #34C759;
        border-radius: 10px;
        width: 125px;
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #FFFFFF;
        cursor: pointer;
        margin: 15px auto 20px auto;
        transition: .25s;
    }
    &_btn:hover {
        background: #39db61;
    }
    &_btn:active {
        background: #2fb450;
    }
    &_sold {
        background: #8d8d8d;
        color: #ffffff;
        font-weight: 600;
        cursor: not-allowed;
        border-radius: 10px;
        width: 125px;
        height: 41px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        margin: 15px auto 20px auto;
    }
    &_include {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;

        //padding-bottom: 30px;
        padding-right: 10px;
        margin: 0;
        margin-right: 10px;
        margin-bottom: 10px;
    }
    &_description {
        display: block;
        padding: 0 20px 0 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #000000;
        margin-bottom: 10px;
    }
    &_attention {
        padding: 0;
        padding-left: 20px;
        list-style-type: none;
        font-weight: 700;
        font-size: 13px;
        color: #000000;
        margin: 0;
        margin-top: 10px;
        margin-bottom: 20px;
    }
    &_footnotes {
        display: block;
        padding: 0;
        padding-left: 20px;
        list-style-type: none;
        font-weight: 400;
        font-size: 12px;
        color: #000000;
        margin: 0;
        margin-top: 10px;
        padding-bottom: 5px;
        li {
            margin-bottom: 10px;
        }
    }
    &_drop-down {
        max-height: 70px;
        transition: all .7s ease-out;
        margin-bottom: 10px;
        
    }
    &_open-more {
        opacity: 1;
        transition: opacity .3s ease-in-out, height .3s ease-in-out;
        position: relative;
        height: 80px;
        top: 1px;
        //background: linear-gradient(rgba(255, 255, 255, 0.18), rgba(255, 255, 255, 0.88) 22.69%, #ffffff);
        background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0),  rgba(255, 255, 255, .9), #fff);
        cursor: pointer;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 10px;
        span {
            cursor: pointer;
            display: block;
            color: #0060aa;
            font-weight: 500;
            font-size: 14px;
            border-bottom: 1px dotted rgba(0, 97, 170, .6);
        }
    }
    &_gallery {
        background: #fff;
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    &_slider {
        display: block;
        max-width: 335px;
        max-height: 180px;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        overflow: hidden;
        will-change: all;
        background: rgba(130, 130, 130, 0.2);
        background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
        background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
        background-size: 800px 100px;
        animation: wave-lines 2s infinite ease-out;
    }
    &_slide {
        display: block;
        width: 100%;
        height: 180px;
        &_img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}   

.open-text {
    .modal-more{
        &_include {
            max-height: 100%;
        }
        &_open-more {
            opacity: 0;
        }
    }
}

.shine {
    background-image: linear-gradient(270deg, rgba(100, 239, 100, 0) 48.44%, #ffffff96 75.52%, rgba(100, 239, 132, 0) 100%);
    background-repeat: no-repeat;
    animation: bg-move linear 5s infinite;
    will-change: transform;
}

@keyframes bg-move {
    0% {
        background-position: -500px 0;
    }
        
    100% {
        background-position: 1000px 0;
    }    
}

// .swiper-slide {
//     display: block;
//     width: 100%;
//     height: 100%; 
// }

@media (max-width: 500px) {
    .modal-more {
        &_container {
            transition: transform 0.55s cubic-bezier(0.55, 0.04, 0, 1.36);
            margin-bottom: 0;
            border-radius: 16px 16px 0 0;
            // min-height: 100vh; 
            display: flex;
            flex-direction: column;
        }
        &_scroll {
            padding-bottom: 0px;
        }
        &::after {
            content: "";
            display: block;
            height: 50px; /* Adjust the height as necessary to cover the gap */
            background: #fff; /* Match the background color of the modal */
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
        }
        &_wrapper {
            background: #fff;
            border-radius: 16px 16px 0 0;
            flex-grow: 1;
           // padding-bottom: 20px;
        }
    }
}


@keyframes wave-lines {
    0% {
        background-position: -468px 0;
    }
     100% {
        background-position: 468px 0;
    }
}