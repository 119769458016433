.contacts {
    padding-top: 125px;
    &_container {
        max-width: 1380px;
        margin: auto;
        padding: 0 10px;
    }

    &_wrapper {
        display: flex;
        flex-direction: column;
    }

    &_main {
        position: relative;
        min-height: 1140px;
    }

    &_left {
        position: -webkit-sticky;
        position: sticky;
        top: 100px;
        width: 40%;
        &_title {
            font-size: 55px;
            color: var(--theme-main-ticket-button-text);
            max-width: 540px;
            font-weight: 600;
        }
        &_subtitle {
            font-size: 24px;
            color: var(--theme-main-ticket-button-text);
            max-width: 500px;
            font-weight: 400;
        }
    }

    &_right {
        padding: 45px;
        background: #ffffffb3;
        backdrop-filter: blur(7.5px);
        border-radius: 24px;
        width: 55%;
        position: absolute;
        right: 0;
        top: 0;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        &_item {
            display: flex;
            max-width: 680px;
            width: 100%;

            &_image {
                min-width: 115px;
                height: 115px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 22px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
                img {
                    width: 50px;
                    height: 50px;
                }
            }
            &_details {
                display: flex;
                flex-direction: column;
                &_title {
                    font-size: 22px;
                    color: var(--theme-main-ticket-button-text);
                    font-weight: 600;
                    margin: 0;
                    margin-top: 17px;
                }
                &_subtitle {
                    font-size: 16px;
                    color: var(--theme-main-ticket-button-text);
                    font-weight: 500;
                    margin-top: 11px;
                }
                &_link-block {
                    margin-top: 11px;
                    &_name {
                        font-size: 20px;
                        color: var(--theme-main-ticket-button-text);
                        font-weight: 500;
                    }
                    &_link {
                        font-size: 20px;
                        color: var(--theme-main-ticket-button-text);
                        font-weight: 500;
                        text-decoration: none;
                    }
                }
            }
        }
        &_item:not(:last-child) {
            margin-bottom: 55px;
        }
    }

    &_images {
        margin-top: 100px;
        display: flex;
        justify-content: space-between;
        &_item {
            background: #ffffff;
            width: 50%;
            display: flex;
            flex-direction: column;
            padding: 25px;
            padding-bottom: 50px;
            &_text {
                font-size: 25px;
                color: #8c8c8c;
                margin-top: 10px;
                font-weight: 600;
            }
            &_img {
                max-width: 600px;
                width: 100%;
                margin: 0 auto;
            }
        }
        &_item:first-child {
            transform: rotate(-4deg);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        }
        &_item:last-child {
            transform: rotate(4deg);
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        }
    }
}

@media (max-width: 1190px) {
    .contacts {
        &_left {
            &_title {
                font-size: 45px;
            }
            &_subtitle {
                font-size: 22px;
            }
        }
        &_right {
            padding: 30px;
        }
    }
}

@media (max-width: 970px) {
    .contacts {
        &_left {
            &_title {
                font-size: 35px;
            }
            &_subtitle {
                font-size: 18px;
            }
        }
        &_right {
            padding: 30px;
            &_item {
                &_image {
                    min-width: 80px;
                    height: 80px;
                    img {
                        width: 40px;
                        height: 40px;
                    }
                }
            }
        }
        &_images {
            margin-top: 150px;
        }
    }
}

@media (max-width: 870px) {
    .contacts {
        &_left {
            position: relative;
            top: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 40px;
            &_title {
                // text-align: center;
            }
            &_subtitle {
                // text-align: center;
                max-width: 400px;
            }
        }
        &_right {
            position: relative;
            width: 100%;
            padding: 20px;
            &_item {
                &_image {
                    border-radius: 16px;
                }
                &_details {
                    &_title {
                        margin-top: 6px;
                    }
                }
            }
        }
        &_main {
            display: flex;
            flex-direction: column;
        }
        &_images {
            margin-top: 30px;
            flex-direction: column;
            align-items: center;
            &_item {
                width: 80%;
                margin-bottom: 20px;
                padding: 15px;
                padding-bottom: 20px;
                &_text {
                    font-size: 16px;
                }
            }
        }
    }
}
