.places {
    &_container {
        max-width: 770px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
    }
}