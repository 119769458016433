
.how-to-get {
    &__container {
        max-width: 985px;
        margin: auto;
        padding: 0 10px;
    }
    &__wrapper {
        margin-top: 60px;
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
    }
    &__item {
        display: flex;
        margin-bottom: 50px;
        background: #ffffffb3;
        backdrop-filter: blur(7.5px);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        border-radius: 16px;
        padding: 30px;
        &_left {
            // min-width: 205px;
            margin-right: 20px;
            position: relative;
            display: flex;
            &_count {
                position: absolute;
                left: -20px;
                top: 95px;
                letter-spacing: -20px;
                font-weight: 700;
                font-size: 120px;
                line-height: 0;
                color: var(--theme-main-application-button);
                user-select: none;
                &_disable {
                    color: #7e7e7e;
                }
            }
            &_img {
                background-color: #595959ce;
                border-radius: 16px;
                overflow: hidden;
                height: 140px;
                width: 140px;
                margin-left: 20px;
            }
        }
        
        &_right {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &_btns {
                height: 40px;
                display: flex;
            }
            &_title {
                font-weight: 600;
                font-size: 20px;
                line-height: 23px;
                color: #000;
                margin-bottom: 10px;
                &_disable {
                    color: #7b7b7b;
                }
            }
            &_text {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #000;
                margin-bottom: 15px;
            }
            &_btn {
                min-width: 120px;
                padding: 12px 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                background:var(--theme-main-application-button);
                border-radius: 10px;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
                text-decoration: none;
                margin: 0px 10px 0 0;
                cursor: pointer;
                transition: .2s ease;
                -webkit-appearance: none;
            }
            &_btn:hover {
                background: var(--theme-main-ticket-button-hover);
            }
            &_btn:active {
                background: var(--theme-main-ticket-button-active);
            }
        }
        
    }
}

@media (max-width: 849px) {
    .how-to-get {
        &__item {
            &_left {
                align-items: flex-start;
                min-width: 50px;
                &_count {
                    left: 0;
                    bottom: -32px;
                    font-size: 70px;
                }
                &_img {
                    display: none;
                }
            }
        }
    }
}

.modal-route {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: scroll; /* Enable horizontal scrolling */
    width: 100%;

    img {
      display: flex;
      width: 100%;
      
    }
}

// .css-ypiqx9-MuiDialogContent-root, .css-1ty026z {
//     padding: 0 !important;
// }

.css-12rl710-MuiPaper-root-MuiDialog-paper, .css-1qmc5dd {
    margin: 0 !important;
}

.zoom-animation {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    animation: fadeOut 2.5s 1;
    transition: all 0.3s ease-in-out;
}

@keyframes fadeOut {
    0%, 
    90% {
        background: #1313137b;
    }
    100% {
        background: #13131300;
    }
  }
  

@media(max-width: 768px) {
    .zoom-animation {
        display: block;
    }
}  