#gradient-canvas {
    position: fixed;
    z-index: -2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    --gradient-color-1: #cbff2a;
    --gradient-color-2: #3dbbac;
    --gradient-color-3: #e5ff49;
    --gradient-color-4: #00a3d7;
}
