.alfabank-faq {
    display: flex;
    flex-direction: column;
    margin: 20px 40px;
    &_item {
        padding: 32px;
        background: #ffffffb3;
        backdrop-filter: blur(7.5px);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            &_text {
                font-size: 24px;
                font-weight: 700;
                margin: 0;
            }
            &_icon {
                background-color: #ffffff;
                border-radius: 50%;
                color: #959595;
                flex-shrink: 0;
                height: 40px;
                margin-left: 12px;
                padding: 12px;
                transition: transform .4s;
                width: 40px;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            &_body {
                &_text {
                    p {
                        font-size: 18px;
                        line-height: 26px;
                        padding-top: 12px;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}