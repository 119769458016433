.progress {
    position: relative;
    display: block;
    margin: 0 auto;
    width: 175px;
    height: 15px;
    border-radius: 20px;
    overflow: hidden;
    margin-top: 20px;
    background: #D9D9D9;
    &_text {
        width: 100%;
        display: block;
        z-index: 2;
        position: absolute;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
    }
    &_active {
        transition: .3s ease;
        position: absolute;
        width: 30%;
        height: 100%;
        background: #007C00;
        border-radius: 20px;
    }
}