.application-modal_final {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    will-change: transform;
    transition: transform 0.45s cubic-bezier(0.54, 0.04, 0, 1.36);
    animation: animate-application 0.6s ease-out;
    &_icon {
        width: 70px;
        margin-bottom: 40px;
    }
    &_title {
        font-weight: 500;
        text-align: center;
        margin-bottom: 20px;
    }
    &_text {
        text-align: center;
        margin-bottom: 20px;
    }
}