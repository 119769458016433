.places-ticket {
    display: flex;
    border-radius: 16px;
    margin-bottom: 20px;
    background: #fff;
    &_wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 16px;
        border: 2px solid #BBBBBB;
    }
    &_information {
        display: flex;
        justify-content: space-between;
        padding: 25px 25px 0 25px;
    }
    &_name {
        &_title {
            margin: 0;
            font-weight: 600;
            font-size: calc(16px + 2 * (100vw / 1280));
            color: #000000;
        }
        &_text {
            display: block;
            font-weight: 400;
            font-size: calc(14px + 2 * (100vw / 1280));
            line-height: 19px;
            color: #000000;
            margin-top: 5px;
            max-height: 19px
        }
    }
    &_btns {
        display: flex;
        justify-content: space-between;
        padding: 35px 25px 25px 25px;
        &_more, &_buy, &_sold {
            width: 125px;
            height: 43px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            border: none;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-decoration: none;
            cursor: pointer;
            transition: .25s;
        }
        &_more {
            background: #e1e1e1;
            color: #000000;
        }
        &_more:hover {
            background: #c9c9c9;
        }
        &_more:active {
            background: rgb(188, 188, 188);
        }
        &_buy {
            background: #34C759;
            color: #ffffff;
            font-weight: 600;
            margin-left: auto;
        }
        &_sold {
            background: #8d8d8d;
            color: #ffffff;
            font-weight: 600;
            cursor: not-allowed;
            margin-left: auto;
        }
        &_buy:hover {
            background: #39db61;
        }
        &_buy:active {
            background: #2fb450;
        }
    }
    &_price {
        display: flex;
        &_text {
            display: flex;
            align-items: center;
            font-size: 22px;
            font-weight: 900;
            color: #aa2424;
            padding: 0 5px;
            border: 3px solid #aa2424;
            //rotate: 5deg;
            min-width: 121px;
            height: 30px;
        }
        &_current {
            display: flex;
            position: relative;
            &_close {
                rotate: -10deg !important;
                display: none;
                position: absolute;
                top: 10px;
                left: -2px;
                width: 105%;
                height: 3px;
                border-radius: 10px;
                background: #c63030aa;
            }
        }
        &_whole {
            display: block;
            width: max-content;
            text-align: end;
        }
        &_whole, &_currency {
            font-weight: 600;
            font-size: 22px;
            color: #000000;
            margin: 0;
        }
        &_fractional {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
        }
        &_currency {
            margin-left: 5px;
        }
        &_all {
            cursor: pointer;
            margin-top: 2px;
            margin-left: 5px;
            position: relative;
        }
    }
    &_prices {
      display: flex;
      align-items: center; 
      position: absolute;
      &_info {
        //background: url(../img/info.svg);
        width: 28px;
        height: 28px;
        background-size: contain;
        margin-left: 10px;
        cursor: pointer;
        position: relative;
        &_block {
          display: flex;
          opacity: 0;
          position: absolute;
          right: 5px;
          width: 120px;
          padding: 10px 0;
          background: white;
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: auto;
          transition: all .3s ease-in-out;
          z-index: 1;
        }
    }
}
    
}

.price {
  &_old {
    font-weight: 300;
    text-decoration-line: line-through;
  }
  &_now {
    font-weight: 700;
  }
  &_next {
    font-weight: 300;
  }
}

.shine {
    background-image: linear-gradient(270deg, rgba(100, 239, 100, 0) 48.44%, #ffffff96 75.52%, rgba(100, 239, 132, 0) 100%);
    background-repeat: no-repeat;
    animation: bg-move linear 5s infinite;
    will-change: transform;
}

@keyframes bg-move {
    0% {
        background-position: -500px 0;
    }
        
    100% {
        background-position: 1000px 0;
    }    
}

@media (max-width: 670px) {
    .places-ticket {
        &_wrapper {
            width:100%;
        }
    }
}

@media (max-width: 500px) {
    .places-ticket {
        &_information {
            padding: 15px 15px 0 15px;
            flex-direction: column;
            align-items: center;
        }
        &_name {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &_title, &_text {
                text-align: center;
            }
            &_text {
                max-height: 100%;
            }
        }
        &_price {
            margin-top: 10px;
            &_whole {
                width: auto;
            }
        }
        &_btns {
            padding: 15px 15px 15px 15px;
            padding-top: 15px;
        }
    } 
}

@media (max-width: 440px) {
    .places-ticket {
        &_btns {
            &_more, &_buy, &_sold {
                width: calc(50% - 10px);
            }
        }
    }
}