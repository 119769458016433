.section-navigation {
    margin-top: 47px;
    &__container {
        max-width: 1520px;
        margin: auto;
        padding: 0 5%;
    }
    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid var(--theme-main-ticket-button-text);
    }
    &__title {
        &_text {
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            margin: 0;
            color: var(--theme-main-ticket-button-text);
        }
    }
    &__buttons {
        width: 390px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__item {
            margin: 0 auto;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: var(--theme-main-ticket-button-text);
            text-decoration: none;
            cursor: pointer;
        }
        &__primary {
            font-weight: 700;
            background-color: #FFFFFF;
            border-radius: 30px;
            color: #D73A2B;
            padding: 8px 20px;
            transition: background-color .2s linear,color .2s linear, box-shadow .2s linear;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        }
        &__primary:hover {
            background-color: #ebebeb;
            box-shadow: 0 5px 15px 0 rgba(235, 235, 235, 0.4);
        }
    }
}

@media (max-width: 768px) {
    .section-navigation {
        &__wrapper {
            flex-direction: column;
        }
        &__title {
            margin-bottom: 20px;
        }
        &__buttons {
            flex-direction: column;
            width: 100%;
            &__item {
                margin: 10px 0;
                font-weight: 500;
                font-size: 16px;
                text-decoration: underline;
            }
            &__primary {
                text-decoration: none;
            }
        }
    }
}