.page-title {
    padding-top: 125px;
    margin-bottom: 50px;
    &_contatiner {
        max-width: 1519px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &_title {
        font-weight: 600;
        font-size: calc(24px + 8 * (100vw / 1280));
        text-align: center;
        color: #000;
        text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.25);
        margin: 0;
    }
    &_subtitle {
        font-weight: 400;
        font-size: calc(18px + 10 * (100vw / 1280));
        text-align: center;
        color: #000;
        text-shadow: 0px 0px 25px rgba(255, 255, 255, 0.25);
        margin: 0;
    }
}