.payment {
    &_container {
        max-width: 872px;
        margin: auto;
        padding: 0 10px;
    }
    &_wrapper {
        display: flex;
        flex-direction: column;
    }
    &_title {
        font-weight: 600;
        font-size: calc(16px + 8 * (100vw / 1280));
        text-align: center;
        color: var(--theme-main-ticket-button-text);
        margin: 0;
        margin-bottom: 15px;
        width: 100%;
    }
    &_title::after {
        display: block;
        content: '';
        width: 95%;
        margin: 0 auto;
        height: 2.5px;
        margin-top: 10px;
        background: var(--theme-headerline);;
        border-radius: 10px;
    }
    &_info {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: var(--theme-main-ticket-button-text);
        margin-bottom: 50px;
        a {
            color: var(--theme-main-ticket-button-text);
        }
    }
    &_block {
        display: flex;
        flex-direction: column;
    }
    &_list {
        padding-left: 20px;
        margin: 0;
        margin-bottom: 40px;
        &_item {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: var(--theme-main-ticket-button-text);
            a {
                color: var(--theme-main-ticket-button-text);
                b {
                    div {
                        display: inline;
                        color: #FF9900; 
                    }
                }
            }
        }
        &_info {
            display: block;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: var(--theme-main-ticket-button-text);
            margin-bottom: 10px;
            margin-top: 15px;
        }
    }
}